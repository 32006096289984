import React, { useContext } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { AuthContext } from './authentication/AuthContext'; // Import the AuthContext
import LogoutButton from './authentication/LogoutButton';  // Import the LogoutButton component

function Header() {
  const { user } = useContext(AuthContext); // Get the authenticated user

  return (
    <Navbar bg="dark" variant="dark" expand="lg">
      <Container>
        <Navbar.Brand href="/">My Web App</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <LinkContainer to="/">
              <Nav.Link>Home</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/analytics">
              <Nav.Link>Analytics</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/chat">
              <Nav.Link>Chat</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/create">
              <Nav.Link>Create</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/marketing">
              <Nav.Link>Marketing</Nav.Link>
            </LinkContainer>

            {user ? (
              // If the user is logged in, show the email and LogoutButton component
              <>
                <Nav.Link disabled>{user.email}</Nav.Link>
                <LogoutButton /> {/* Use the LogoutButton component here */}
              </>
            ) : (
              // If the user is not logged in, show the login link
              <LinkContainer to="/login">
                <Nav.Link>Login</Nav.Link>
              </LinkContainer>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
