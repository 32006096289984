import React from 'react';
// import { Container, Row, Col } from 'react-bootstrap';
// import Header from '../Header';
import FeatureSection from './FeatureSection';
import AboutSection from './AboutSection';
import ContactSection from './ContactSection';
// import Footer from '../Footer';

function LandingPage() {
  return (
    <div>
      <FeatureSection />
      <AboutSection />
      <ContactSection />
    </div>
  );
}

export default LandingPage;