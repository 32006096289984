import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';

function AboutSection() {
  return (
    <Container id="about" className="my-5">
      <Row>
        <Col md={6}>
          <Image src="https://via.placeholder.com/500" fluid />
        </Col>
        <Col md={6}>
          <h2>About Our App</h2>
          <p>
            Our app is designed for users who want to achieve their goals in a smarter and more efficient way.
            With advanced features and a user-friendly interface, it's perfect for people looking to simplify their tasks.
          </p>
          <p>
            Whether you're a busy professional, student, or just someone who values convenience, this app is built with you in mind.
          </p>
        </Col>
      </Row>
    </Container>
  );
}

export default AboutSection;
