import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import ConversationStarter from './ConversationStarter'
import ChatWindow from '../chat/ChatWindow';

function Create() {
  return (
    <Container className="my-5">
      <Row>
        <Col>
          <h1>Create Chatbot</h1>
          <p>This page provides allows users to create custom chatbots</p>
        </Col>
      </Row>
      <Row>
        <Col>
            <Form>
            <Form.Group className="mb-3" controlId="formChatbot">
                <Form.Label>Name</Form.Label>
                <Form.Control type="text" placeholder="Enter chatbot name" />
                <Form.Text className="text-muted">
                Name your chatbot
                </Form.Text>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Description</Form.Label>
                <Form.Control type="text" placeholder="Add a short description of what this chatbot does" />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Instructions</Form.Label>
                <Form.Control as="textarea" rows={3} placeholder="What does this chatbot do? How does it behave? What should it avoid doing?" />
            </Form.Group>
            <ConversationStarter />
            <Form.Group className="mb-3" controlId="formChatbot">
                <Form.Label>Knowledge</Form.Label>
                <br></br>
                <Form.Text className="text-muted">
                Files uploaded in this section may be used by your chatbot to answer questions
                </Form.Text>
            </Form.Group>
            <Button variant="primary" type="submit">
                Submit
            </Button>
            </Form>
        </Col>
        <Col>
            <ChatWindow />
        </Col>
        </Row>
    </Container>
  );
}

export default Create;