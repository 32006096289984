import React from 'react';
import { ListGroup, Badge } from 'react-bootstrap';

const MessageBubble = ({ message }) => {
  const isUser = message.sender === 'user';
  return (
    <ListGroup.Item
      className={`d-flex ${isUser ? 'justify-content-end' : 'justify-content-start'}`}
      style={{ border: 'none', backgroundColor: 'transparent' }}
    >
      <Badge
        pill
        bg={isUser ? 'success' : 'secondary'}
        style={{ maxWidth: '75%', padding: '10px', wordBreak: 'break-word' }}
      >
        {message.text}
      </Badge>
    </ListGroup.Item>
  );
};

export default MessageBubble;
