import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

function FeatureSection() {
  return (
    <Container id="features" className="my-5">
      <h2 className="text-center mb-4">App Features</h2>
      <Row>
        <Col md={4}>
          <Card className="mb-4">
            <Card.Body>
              <Card.Title>Feature 1</Card.Title>
              <Card.Text>
                Description of what feature 1 does and how it helps users.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="mb-4">
            <Card.Body>
              <Card.Title>Feature 2</Card.Title>
              <Card.Text>
                Description of what feature 2 does and its importance.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="mb-4">
            <Card.Body>
              <Card.Title>Feature 3</Card.Title>
              <Card.Text>
                Details about feature 3 and how it benefits your users.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default FeatureSection;
