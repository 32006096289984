import React from 'react';
import { ListGroup } from 'react-bootstrap';
import MessageBubble from './MessageBubble';

const MessageList = ({ messages }) => {
  return (
    <ListGroup className="w-100">
      {messages.map((message, index) => (
        <MessageBubble key={index} message={message} />
      ))}
    </ListGroup>
  );
};

export default MessageList;
