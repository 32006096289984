import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import UserProfile from '../authentication/UserProfile';

function Analytics() {
  return (
    <Container className="my-5">
      <Row>
        <Col>
          <h1>Analytics</h1>
          <p>This page allows chatbot owners to derive insights from their users.</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <UserProfile />
        </Col>
    </Row>
    </Container>
  );
}

export default Analytics;