import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import MessageList from './MessageList';
import ChatInput from './ChatInput';

const ChatWindow = () => {
  const [messages, setMessages] = useState([
    { text: 'Hello! How can I assist you today?', sender: 'bot' }
  ]);

  // Function to handle sending a new message
  const sendMessage = (messageText) => {
    if (messageText.trim() !== '') {
      // Add the user's message
      setMessages([...messages, { text: messageText, sender: 'user' }]);

      // Simulate bot response after a short delay
      setTimeout(() => {
        setMessages((prevMessages) => [
          ...prevMessages,
          { text: `You said: "${messageText}"`, sender: 'bot' }
        ]);
      }, 1000);
    }
  };

  return (
    <Container className="border rounded chat-window" style={{ maxWidth: '400px', height: '500px', display: 'flex', flexDirection: 'column' }}>
      <Row className="bg-primary text-white p-2">
        <Col>
          <h5 className="text-center">Chat with Us</h5>
        </Col>
      </Row>
      <Row style={{ flex: '1', overflowY: 'auto', padding: '10px' }}>
        <MessageList messages={messages} />
      </Row>
      <Row className="p-2">
        <ChatInput onSend={sendMessage} />
      </Row>
    </Container>
  );
};

export default ChatWindow;
