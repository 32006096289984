import React, { useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';

function ConversationStarter() {
  const [forms, setForms] = useState([{ id: 1, value: '' }]);

  // Handle input change
  const handleChange = (e, id) => {
    const updatedForms = forms.map(form =>
      form.id === id ? { ...form, value: e.target.value } : form
    );
    setForms(updatedForms);

    // Add new form if text is entered and it's the last form
    if (e.target.value && id === forms.length) {
      setForms([...updatedForms, { id: forms.length + 1, value: '' }]);
    }
  };

  // Handle clearing input and removing the form if there are multiple forms
  const handleRemoveForm = (id) => {
    if (forms.length > 1) {
      const updatedForms = forms.filter(form => form.id !== id);
      setForms(updatedForms);
    } else {
      // If it's the only form, just clear the text
      const updatedForms = forms.map(form =>
        form.id === id ? { ...form, value: '' } : form
      );
      setForms(updatedForms);
    }
  };

  return (
    <div>
      {forms.map(form => (
        <InputGroup className="mb-3" key={form.id}>
          <Form.Control
            type="text"
            placeholder="Enter text"
            value={form.value}
            onChange={(e) => handleChange(e, form.id)}
          />
          <Button
            variant="outline-secondary"
            onClick={() => handleRemoveForm(form.id)}
          >
            X
          </Button>
        </InputGroup>
      ))}
    </div>
  );
}

export default ConversationStarter;
