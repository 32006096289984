import React, { useState } from 'react';
import { signInWithPopup } from 'firebase/auth';
import { auth } from '../../firebase';
import { GoogleAuthProvider } from 'firebase/auth'; // Import Google provider
import { useNavigate } from 'react-router-dom';

function Login() {
  const [error, setError] = useState('');
  const navigate = useNavigate();

  // Initialize Google provider
  const googleProvider = new GoogleAuthProvider();

  // Google login
  const handleGoogleLogin = async () => {
    try {
      await signInWithPopup(auth, googleProvider);
      navigate('/analytics');
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div>
      <h2>Login</h2>
      {error && <p>{error}</p>}

      <button onClick={handleGoogleLogin}>Login with Google</button>
    </div>
  );
}

export default Login;
