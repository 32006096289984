import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import LandingPage from './components/landing-page/LandingPage';
import Analytics from './components/analytics/Analytics';
import Chat from './components/chat/Chat';
import Create from './components/create/Create';
import Marketing from './components/marketing/Marketing';
import Header from './components/Header';
import Footer from './components/Footer';
import Login from './components/authentication/Login';
import PrivateRoute from './components/authentication/PrivateRoute';
import { AuthProvider } from './components/authentication/AuthContext';
import PasswordPage from './components/passwordpage/PasswordPage';


function App() {
  const [authenticated, setAuthenticated] = useState(false);
  
  return (
    <>
      {!authenticated ? (
        // Show password page if not authenticated
        <PasswordPage onAuthenticated={setAuthenticated} />
      ) : (
      // Render the rest of the app after password authentication
        <AuthProvider>
          <Header />
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/analytics" element={<PrivateRoute element={Analytics} />} />
            <Route path="/chat" element={<Chat />} />
            <Route path="/create" element={<Create />} />
            <Route path="/marketing" element={<Marketing />} />
          </Routes>
          <Footer />
        </AuthProvider>
      )}
    </>
  );
}

export default App;
