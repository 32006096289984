// import axios from 'axios';
import { auth, db } from './firebase'; // Ensure `auth` and `db` are initialized in `firebase.js`
import { doc, getDoc, setDoc } from 'firebase/firestore';

/**
 * Fetches user data from Firestore using the authenticated user's UID.
 */
export const fetchUserData = async () => {
  try {
    const currentUser = auth.currentUser;

    if (currentUser) {
      const userDocRef = doc(db, 'users', currentUser.uid);
      const userDoc = await getDoc(userDocRef);
      
      if (userDoc.exists()) {
        return userDoc.data();
      } else {
        console.log('No such document!');
        return null;
      }
    } else {
      console.log('No user is logged in');
      return null;
    }
  } catch (error) {
    console.error('Error fetching user data:', error);
    throw error;
  }
};

/**
 * Updates or creates a user document in Firestore using Axios.
 * This method can be used during the first login to save user data.
 */
export const updateUserData = async () => {
  try {
    const currentUser = auth.currentUser;

    if (currentUser) {
      const { uid, displayName, email, photoURL } = currentUser;

      // Data to be stored in the Firestore user document
      const userData = {
        name: displayName,
        email: email,
        photoURL: photoURL,
      };

      const userDocRef = doc(db, 'users', uid);

      // Use setDoc to create or update the document in Firestore
      await setDoc(userDocRef, userData, { merge: true });

      console.log('User data updated successfully!');
      return userData;
    } else {
      console.log('No user is logged in');
      return null;
    }
  } catch (error) {
    console.error('Error updating user data:', error);
    throw error;
  }
};
