import React, { useState } from 'react';
import { Container, Form, Button, Alert, Card } from 'react-bootstrap';
import PrivacyPolicy from '../privacypolicy/PrivacyPolicy'; // Import PrivacyPolicy component
import './PasswordPage.css'; // Import custom CSS for additional styling

const PasswordPage = ({ onAuthenticated }) => {
  const [password, setPassword] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false); // State to toggle privacy policy

  const checkPassword = () => {
    const correctPassword = 'kenai'; // Replace with your chosen password
    if (password === correctPassword) {
      onAuthenticated(true); // Notify the parent component that the password is correct
    } else {
      setShowAlert(true);
    }
  };

  return (
    <div className="password-page-bg">
      <Container className="d-flex flex-column align-items-center justify-content-center vh-100">
        <Card className="p-4 shadow-lg w-75" style={{ maxWidth: '800px', backgroundColor: '#013a63', borderRadius: '10px' }}>
          <h2 className="text-center mb-4 fw-bold" style={{ color: '#ff6f61' }}>Luplab</h2>
          <h5 className="text-center mb-4" style={{ color: '#cce7ff' }}>
            Helping influencers scale engagement & create standout content with AI
          </h5>          
          <Form>
            <Form.Group controlId="passwordInput">
              <Form.Control
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                style={{ borderColor: '#ff6f61', boxShadow: '0 0 5px rgba(255, 76, 76, 0.5)' }}
              />
            </Form.Group>
            <Button variant="primary" className="mt-3 w-100 fw-bold" onClick={checkPassword} style={{ backgroundColor: '#ff6f61', borderColor: '#ff6f61' }}>
              Submit
            </Button>
            {showAlert && (
              <Alert variant="danger" className="mt-3">
                Incorrect password. Please try again.
              </Alert>
            )}
          </Form>
          {/* Toggle Privacy Policy Button */}
          <Button
            variant="link"
            className="mt-3"
            onClick={() => setShowPrivacyPolicy(!showPrivacyPolicy)}
            style={{ color: '#ff6f61', textDecoration: 'underline' }}
          >
            {showPrivacyPolicy ? 'Hide Privacy Policy' : 'View Privacy Policy'}
          </Button>
          {showPrivacyPolicy && (
            <div className="mt-3">
              <PrivacyPolicy />
            </div>
          )}
        </Card>
      </Container>
    </div>
  );
};

export default PasswordPage;
