import React, { useEffect, useState } from 'react';
import { fetchUserData, updateUserData } from '../../api'; // Import functions

function UserProfile() {
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const getUserData = async () => {
      try {
        // First, update user data (useful for first-time login)
        await updateUserData();
        
        // Then fetch user data
        const data = await fetchUserData();
        setUserData(data);
      } catch (error) {
        console.error('Failed to fetch user data:', error);
      }
    };

    getUserData();
  }, []);

  if (!userData) {
    return <p>Loading user data...</p>;
  }

  return (
    <div>
      <h1>Welcome, {userData.name}!</h1>
      <p>Email: {userData.email}</p>
      {userData.photoURL && <img src={userData.photoURL} alt="Profile" />}
    </div>
  );
}

export default UserProfile;
