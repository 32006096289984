import React, { useState } from 'react';
import { InputGroup, FormControl, Button } from 'react-bootstrap';

const ChatInput = ({ onSend }) => {
  const [inputValue, setInputValue] = useState('');

  // Handle input change
  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    onSend(inputValue);
    setInputValue(''); // Clear the input field
  };

  return (
    <form onSubmit={handleSubmit} style={{ width: '100%' }}>
      <InputGroup>
        <FormControl
          placeholder="Type a message..."
          value={inputValue}
          onChange={handleChange}
        />
        <Button type="submit" variant="primary">
          Send
        </Button>
      </InputGroup>
    </form>
  );
};

export default ChatInput;
