import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

function Marketing() {
  return (
    <Container className="my-5">
      <Row>
        <Col>
          <h1>Marketing</h1>
          <p>This page provides more detailed information about our app's services for marketers.</p>
        </Col>
      </Row>
    </Container>
  );
}

export default Marketing;