import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ChatWindow from './ChatWindow';

function Chat() {
  return (
    <Container className="my-5">
      <Row>
        <Col>
          <h1>Chat</h1>
          <p>This page allows users to chat with chatbots.</p>
          <ChatWindow />
        </Col>
      </Row>
    </Container>
  );
}

export default Chat;